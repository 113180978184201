.row {
  display: flex;
  flex-direction: row;
}

.reportDefault p {
  color: #53a6b7;
  font-size: 15px;
  font-weight: 700;
}
.align-text {
  padding: 10px 5px !important;
}

.delbtn {
  background: #c31818 !important;
  border-color: #c31818 !important;
  /* margin-right: 10%; */
}

.mainheading {
  font-style: italic;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
  font-size: large;
  padding: 4px 10px;
}

.ulstyleextra {
  list-style-type: none;
  padding: 0px 10px;
}

.float-label-extra {
  display: flex;
  flex-direction: column;
  position: relative;
}
.float-label-extra i {
  position: absolute;
  left: 12px;
  font-size: 13px;
  display: block;
  transform: translate(0, -50%);
  top: 35px;
  pointer-events: none;
  width: 24px;
  text-align: center;
  font-style: normal;
}
.float-label-extra .icon {
  padding: 15px 17px 0 29px !important;
}
.float-label-extra input {
  width: 100%;
  height: 56px;
  padding: 15px 17px 0 20px;
  outline: 0;
  border: 1px solid #ddd !important;
  border-radius: 10px !important;
  background: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
}

.float-label-extra textarea {
  width: 100%;
  height: 120px;
  padding: 31px 17px 0 20px;
  outline: 0;
  border: 1px solid #ddd;
  resize: none;
  border-radius: 10px;
  background: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
}

.float-label-extra label {
  font-style: italic;
  font-weight: bold;
  padding: 0px 25px;
  color: #84857a;
  pointer-events: none;
  position: absolute;
  transform: translate(0, 26px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
  margin-top: -8px;
}

.float-label-extra:focus-within label {
  transform: translate(0, 12px) scale(0.75);
}

.float-label-extra .Active {
  transform: translate(0, 12px) scale(0.75);
}

.float-label-extra input {
  max-width: none !important;
}

input:focus {
  outline: none;
}

textarea:focus {
  outline: none;
}

.auth-input-field-extra::placeholder {
  font-style: italic;
  font-weight: bold;
}

.no-border {
  border: none !important;
}

.dialogtextarea {
  margin: 25px 0px 0px 0px;
}

.dialogtextarea textarea {
  resize: none !important;
  height: 40vh !important;
}

.modal-content {
  border-radius: 10px !important;
}

.TabsLabel {
  margin: 7px 0px;
  font-weight: bold;
  color: #2f481e;
}

@media only screen and (max-device-height: 667px) {
  /* Styles */
  .align-text {
    margin-left: 7%;
  }
}
